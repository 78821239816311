import React from 'react'
import { TopNav } from './TopNav'


export const Footer = () => {
  return (
    <div>
        <footer className="footer bg-dark text-light text-center py-5 mt-5">
        Copyritght &copy; all reserved. Made by @SureshMaskey.
      </footer>

      
    </div>
  )
}
